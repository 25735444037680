import React, { Component } from "react";
import Modal from "react-responsive-modal";
import stripeLogo from "../../assets/img/stripe-seeklogo.com.svg";
import decLogo from "../../assets/img/DecNewImg.png";
import Api from "../../services/api";
import wireTransferLogo from "../../assets/img/bank.png";
import btcLogo from "../../assets/img/BTCOrangeSquare.png";
import _ from "lodash";
import WithdrawBTCModal from "./WithdrawBTCModal";
import PaymentRequestModal from "./PaymentRequestModal";
import Stripe from "./Stripe";
import StripeTooltip from "./StripeTooltip";
import WireTooltip from "./WireTooltip";
import BtcTooltip from "./BtcTooltip";
import DecModal from "./DecModal";
import DecTooltip from "./DecTooltip";
import "./modals.css";
let api;

class DepositModal extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      address: "",
      amount: "",
      show: false,
      timeHorizon: "",
      email: "",
      btcModalOpen: false,
      stripeKey: "",
      onOpened: false,
      decModalOpen: false,
      paymentRequestButtonLoading: false,
      paymentRequestModalOpen: false,
      earningData: [],
      senderWalletAddress: "",
      discountRate: "",
      starter: true,
      fileName: "MinerData",
      showInsideModal: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);
    this.onChangeAmount = this.onChangeAmount.bind(this);
    this.withdrawOnCloseModal = this.withdrawOnCloseModal.bind(this);
    this.withdrawShowModal = this.withdrawShowModal.bind(this);
    this.setShow = this.setShow.bind(this);
    this.fileUpdated = this.fileUpdated.bind(this);
    this.onChangeTimeHorizon = this.onChangeTimeHorizon.bind(this);
    this.onPaymentRequestCloseModal =
      this.onPaymentRequestCloseModal.bind(this);
    this.showPaymentRequestModal = this.showPaymentRequestModal.bind(this);
    this.clearTime = this.clearTime.bind(this);
    this.setShowInsideModal = this.setShowInsideModal.bind(this);
    this.setHideInsideModal = this.setHideInsideModal.bind(this);
    this.uploadImageArray = {};
  }

  onCloseDecModal = () => {
    this.setState({ decModalOpen: false });
  };
  onOpenDecModal = () => {
    this.setState({ decModalOpen: true });
  };
  setShowInsideModal() {
    this.setState({ showInsideModal: false }, () => {});
  }

  setHideInsideModal() {
    this.setState({ showInsideModal: true }, () => {});
  }

  async componentDidMount() {
    const response = await api.create("miner/getPaymentCredentials", {
      paymentMode: "stripe",
    });

    if (response.code === 200) {
      this.setState({ stripeKey: response.data[0].key });
    }

    const addreesResponse = await api.create("user/getUserDetails");

    if (addreesResponse.code === 200) {
      this.setState({
        senderWalletAddress: addreesResponse.data.btcWalletAddress,
        email: addreesResponse.data.email,
      });
    }
  }

  async setShow(startDate, endDate) {
    if (startDate && endDate) {
      this.setState({ show: true });
    } else {
      this.setState({ show: false });
    }
  }
  async fileUpdated(fileItems) {
    if (!_.isUndefined(fileItems) && fileItems != null && fileItems !== "") {
      this.uploadImageArray["file"] = fileItems[0].file;
    }
  }
  withdrawOnCloseModal() {
    this.setState({ btcModalOpen: false });
  }
  showPaymentRequestModal() {
    this.setState({ paymentRequestModalOpen: true });
  }
  onPaymentRequestCloseModal() {
    this.setState({ paymentRequestModalOpen: false });
  }
  withdrawShowModal() {
    this.setState({
      btcModalOpen: true,
    });
  }
  async clearTime() {
    this.setState({ startDate: "" });
    this.setState({ endDate: "" });
    this.setState({ timeHorizon: "" });
  }

  async onChangeTimeHorizon(time) {
    this.setState({
      timeHorizon: time,
    });
  }
  async onChangeAddress(e) {
    this.setState(
      {
        address: e.target.value,
      },
      () => {
        this.setShow(this.state.address, this.state.endDate);
      }
    );
  }
  async onChangeAmount(e) {
    this.setState(
      {
        amount: e.target.value,
      },
      () => {
        this.setShow(this.state.startDate, this.state.endDate);
      }
    );
  }
  async onSubmit() {}

  render() {
    const {
      depositModalOpen,
      depositOnCloseModal,
      getRecords,
      invoiceId,
      reference,
      setInvoiceLoading,
      userDetails,
    } = this.props;
    const {
      starter,
      uploadImageArray,
      btcModalOpen,
      paymentRequestModalOpen,
      stripeKey,
      decModalOpen,
      showInsideModal,
      senderWalletAddress,
      email,
    } = this.state;
    const decShow =
      invoiceId && invoiceId.invoiceId && invoiceId.invoiceId.decDiscount
        ? true
        : false;
    return (
      <Modal
        open={depositModalOpen}
        onClose={depositOnCloseModal}
        center
        showCloseIcon={false}
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: `instructon-modal-container billing-payment-modal newOverlay ${
            showInsideModal ? "d-none" : "d-block"
          }`,
        }}
      >
        <div className="onboarding-modal ">
          <div className="modal-dialog " role="document">
            <div className="modal-content ">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div className="onboarding-content onboarding-content2">
                    {starter && (
                      <div>
                        <h2
                          className="modal-heading text-center payHeading  text-black"
                          style={{
                            alignSelf: "flex-start !important",
                          }}
                        >
                          Please Select the Payment Method
                        </h2>
                        {decShow && (
                          <h5
                            className="text-center theme-color"
                            style={{
                              alignSelf: "flex-start !important",
                            }}
                          >
                            Get {invoiceId.invoiceId.decDiscount}% Discount on
                            DEC Payment
                          </h5>
                        )}
                        <div
                          className={`${
                            decShow
                              ? "payment-method-container mt-4"
                              : "d-flex justify-content-center "
                          }`}
                        >
                          <div
                            className={`${
                              decShow ? "d-flex" : "d-flex content-left"
                            }`}
                          >
                            <span
                              className="custom-tooltip-coin2 row justify-content-center"
                              tooltip-title={`import Wallet`}
                            >
                              <button
                                aria-expanded="false"
                                aria-haspopup="true"
                                style={{
                                  background: "transparent",
                                  outline: "none",
                                  border: "none",
                                  padding: "0px",
                                }}
                                className="stripeButton dropdown-toggle stripe-dropdown-item-btn"
                                data-toggle="dropdown"
                                id="dropdownMenuButton6"
                                type="button"
                              >
                                <img
                                  className={`${
                                    decShow
                                      ? "paymentIcons stripe-margin"
                                      : "paymentIcons n-stripe-margin"
                                  }`}
                                  src={stripeLogo}
                                  style={{
                                    borderRadius: "10px",
                                    background: "#e9e9e9",
                                  }}
                                  alt=""
                                />
                              </button>
                              <div aria-labelledby="" className="dropdown-menu">
                                <span
                                  className="mb-2 dropdown-item"
                                  type="button"
                                >
                                  <Stripe
                                    invoiceId={
                                      invoiceId ? invoiceId.invoiceId._id : ""
                                    }
                                    currency="CAD"
                                    stripeKey={stripeKey}
                                    getRecords={getRecords}
                                    reference={reference}
                                    price={Number(
                                      invoiceId.cadElectricityFee
                                    ).toFixed(2)}
                                    userDetails={userDetails}
                                    depositOnCloseModal={depositOnCloseModal}
                                    setShowInsideModal={this.setShowInsideModal}
                                    setHideInsideModal={this.setHideInsideModal}
                                  />
                                </span>
                                <span
                                  className="mb-2 dropdown-item"
                                  type="button"
                                >
                                  <Stripe
                                    invoiceId={
                                      invoiceId ? invoiceId.invoiceId._id : ""
                                    }
                                    currency="USD"
                                    stripeKey={stripeKey}
                                    price={Number(
                                      invoiceId.usdElectricityFee
                                    ).toFixed(2)}
                                    getRecords={getRecords}
                                    reference={reference}
                                    depositOnCloseModal={depositOnCloseModal}
                                    userDetails={userDetails}
                                    setShowInsideModal={this.setShowInsideModal}
                                    setHideInsideModal={this.setHideInsideModal}
                                  />
                                </span>
                              </div>

                              <StripeTooltip />
                            </span>

                            <span
                              // className="custom-tooltip-coin2 row justify-content-center mr-4"
                              className={`${
                                decShow
                                  ? "custom-tooltip-coin2 row justify-content-center mr-4"
                                  : "custom-tooltip-coin2 row justify-content-center wire-margin"
                              }`}
                              tooltip-title={`import Wallet`}
                            >
                              <div
                                className={`${
                                  decShow
                                    ? "paymentIcons hovershadow text-center "
                                    : "paymentIcons hovershadow text-center wire-margin"
                                }`}
                                style={{
                                  background: "black",
                                  borderRadius: "10px",
                                }}
                              >
                                <img
                                  className={"paymentIcons"}
                                  src={wireTransferLogo}
                                  alt=""
                                  style={{
                                    position: "relative",
                                    left: "-5px",
                                    top: "-5px",
                                    filter: "invert(100%)",
                                    borderRadius: "10px",
                                  }}
                                  onClick={() => {
                                    this.showPaymentRequestModal();
                                  }}
                                />
                              </div>
                              <WireTooltip />
                            </span>

                            <PaymentRequestModal
                              {...this.props}
                              {...this.state}
                              onCloseModal={this.onPaymentRequestCloseModal}
                              paymentRequestModalOpen={paymentRequestModalOpen}
                              onChangeCurrencyType={this.onChangeCurrencyType}
                              onAmountChange={this.onAmountChange}
                              onInputValueChange={this.onchange}
                              getRecords={getRecords}
                              email={email}
                              reference={reference}
                              onUpdateAmount={this.onUpdateTransactionAmount}
                              uploadImageArray={uploadImageArray}
                              invoiceId={invoiceId ? invoiceId : ""}
                              displayValidationErrors={
                                this.displayValidationErrors
                              }
                            />
                          </div>
                          <div className="d-flex">
                            <span
                              className={`${
                                decShow
                                  ? "custom-tooltip-coin2 row justify-content-center mr-4"
                                  : "icon-pt-4 custom-tooltip-coin2 row justify-content-center"
                              }`}
                              tooltip-title={`import Wallet`}
                            >
                              <img
                                className={`${
                                  decShow
                                    ? "btc-margin paymentIcons1"
                                    : "paymentIcons1 n-btc-margin"
                                }`}
                                style={{
                                  borderRadius: "15px",
                                }}
                                src={btcLogo}
                                onClick={() => {
                                  this.withdrawShowModal();
                                }}
                                alt=""
                              />

                              <BtcTooltip />
                            </span>

                            {decShow && (
                              <span
                                className={`${
                                  decShow
                                    ? "custom-tooltip-coin2 row justify-content-center dec-margin"
                                    : "d-none"
                                }`}
                              >
                                <img
                                  className="paymentIcons2"
                                  src={decLogo}
                                  onClick={() => {
                                    this.onOpenDecModal();
                                  }}
                                  alt=""
                                />
                                <DecTooltip />
                                <DecModal
                                  decModalOpen={decModalOpen}
                                  depositOnCloseModal={depositOnCloseModal}
                                  discountRate={this.state.discountRate}
                                  getRecords={getRecords}
                                  setInvoiceLoading={setInvoiceLoading}
                                  reference={reference}
                                  onCloseDecModal={this.onCloseDecModal}
                                  invoiceId={invoiceId}
                                />
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="d-flex justify-content-center mt-4 ">
                          <button
                            className="btn modal-button cancel-button mr-2"
                            type="button"
                            onClick={() => {
                              depositOnCloseModal();
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <WithdrawBTCModal
          btcModalOpen={btcModalOpen}
          amount={Number(invoiceId.btcElectricityFee).toFixed(8)}
          amountUsd={Number(invoiceId.usdElectricityFee).toFixed(2)}
          invoiceId={invoiceId ? invoiceId.invoiceId._id : ""}
          depositOnCloseModal={depositOnCloseModal}
          withdrawOnCloseModal={this.withdrawOnCloseModal}
          senderWalletAddress={senderWalletAddress}
          getRecords={getRecords}
          reference={reference}
          onInputValueChange={this.onchange}
        />
      </Modal>
    );
  }
}

export default DepositModal;
